import React, { Component } from 'react';
import { BrowserRouter, NavLink, Route, Routes, useParams } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Nav, Navbar, Col, Container, Row, NavDropdown, Button } from "react-bootstrap";
import '../styles/listadosbdd.css'

export default class ListadosBDD extends Component {
    render() {
        return (
            <body>
                <div>
                    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
                        <Container>
                            <Navbar.Brand href="/admin"><img className='logo' src='https://europeanfundingproject.com/wp-content/uploads/2022/05/EUROPEAN-FUNDING-PROJECT-logo-blanco.png'/></Navbar.Brand>
                            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                            <Navbar.Collapse id="responsive-navbar-nav">
                                <Nav className='right'>
                                    <Nav.Link href="/logout">Log Out</Nav.Link>
                                </Nav>
                            </Navbar.Collapse>
                        </Container>
                    </Navbar>
                    <div style={{ fontSize: 25, margin: 50 }}>
                        <Row>
                            <h1>Con que listado te gustaría trabajar?</h1>
                        </Row>
                    </div>
                    <div className='container' >
                        <div className='w-75 mt-5 mb-5 divAdmin'>
                            <div className='row mt-3'>
                                <div className='col-md-8 titulosAdmin'>Activos</div>
                                <div className='col-md-4'><Button className="btn boton w-50" onClick={() => { window.location.assign("/llista_actius"); }}>Ir!</Button></div>
                            </div>

                            <div className='row mt-3 mb-3'>
                                <div className='col-md-8 titulosAdmin'>Fotografies</div>
                                <div className='col-md-4'><Button className="btn boton w-50" onClick={() => { window.location.assign("/llista_fotografies"); }}>Ir!</Button></div>
                            </div>

                            <div className='row mt-3'>
                                <div className='col-md-8 titulosAdmin'>Usuaris</div>
                                <div className='col-md-4'><Button className="btn boton w-50" onClick={() => { window.location.assign("/llista_usuaris"); }}>Ir!</Button></div>
                            </div>

                            <div className='row mt-3 mb-3 titulosAdmin'>
                                <div className='col-md-8'>Volver atrás</div>
                                <div className='col-md-4'><Button className="btn btn-danger boton-danger w-50" onClick={() => { window.location.assign("/admin"); }}>Ir!</Button></div>
                            </div>
                        </div>
                    </div>
                </div>
            </body>
        );
    }
}
