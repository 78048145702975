import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Nav, Navbar, Col, Container, Row, NavDropdown, Button } from "react-bootstrap";
import '../styles/listadosbdd.css';

export default class Edita_Usuari extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: '',
            email: '',
            password: '',
            admin: '',
            modificat: false
        }
    }

    onChange = (event) => {
        this.setState({ [event.target.name]: event.target.value, modificat: true });
    }

    componentDidMount() {
        if (this.props.id !== "-1") {
            this.descarrega(this.props.id);
        }
        this.setState({
            id: this.props.id,
            admin: '1'
        });
    }


    componentDidUpdate(prevProps) {
        if (this.props.id !== prevProps.id) {
            if (this.props.id !== "-1") {
                this.descarrega(this.props.id);
            } else {
                this.setState({
                    id: this.props.id,
                    email: '',
                    password: '',
                    admin: '',
                    password: ''
                });
            }
        }
    }

    onSubmit = (e) => {
        e.preventDefault();
        if (this.state.id == "-1") {
            this.insertar();
        } else {
            this.modificar();
        }

    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onChangeTipus = (v) => {
        this.setState({ fk_tipus: v, modificat: true });
    }

    descarrega(id) {
        var url = 'https://admin.europeanfundingproject.com/europeanfundingproject/public/api/usuaris/' + id;
        fetch(url, {
            method: 'GET',
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                'Authorization': 'Bearer ' + sessionStorage.getItem("token")
            },
        })
            .then(resposta => {
                return resposta.json()
            })
            .then(respostajson => {
                console.log(respostajson)
                this.setState({
                    id: respostajson.id,
                    email: respostajson.email,
                    password: respostajson.password,
                    admin: respostajson.admin
                });
            })
            .catch(function (error) {
                console.log(error);
            })
    }


    insertar = () => {
        fetch('https://admin.europeanfundingproject.com/europeanfundingproject/public/api/usuaris', {
            method: 'POST',
            body: "email=" + this.state.email  + "&password=" + this.state.password + "&admin=" + this.state.admin,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                'Authorization': 'Bearer ' + sessionStorage.getItem("token")
            },
        })
            .then(resposta => {
                return resposta.json()
            })
            .then(respostajson => {
                if (respostajson.status == "Creat") {
                    alert("Inserció realitzada correctament");
                    window.location.assign("/llista_usuaris");
                } else {
                    alert("Error creant");
                }
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    modificar = () => {
        fetch('https://admin.europeanfundingproject.com/europeanfundingproject/public/api/usuaris/' + this.state.id, {
            method: 'PUT',
            body: "email=" + this.state.email + "&password=" + this.state.password + "&admin=" + this.state.admin,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                'Authorization': 'Bearer ' + sessionStorage.getItem("token")
            },
        })
            .then(resposta => {
                return resposta.json()
            })
            .then(respostajson => {
                if (respostajson.status == "Modificat") {
                    alert("Modificació realitzada correctament");
                    window.location.assign("/llista_usuaris");
                } else {

                    alert("Error modificant");
                }
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    render() {
        return (
            <div>
                <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
                    <Container>
                        <Navbar.Brand href="/admin"><img className='logo' src='https://europeanfundingproject.com/wp-content/uploads/2022/05/EUROPEAN-FUNDING-PROJECT-logo-blanco.png'/></Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className='right'>
                                <Nav.Link href="/listadosbdd">Listados BDD</Nav.Link>
                                <Nav.Link href="/logout">Log Out</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
                <Container>
                    {this.state.id != -1 && <h1 className='email' style={{ padding: 50 }}>Editant la informació de l'usuari: {this.state.email}</h1>}
                    {this.state.id == -1 && <h1 className='email' style={{ padding: 50 }}>Inserint un nou usuari</h1>}
                    <form onSubmit={this.onSubmit}>
                        <div className="row" style={{ padding: 20 }}>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label style={{ fontWeight: 'bold' }}>Codi de l'usuari:</label>
                                    <input type="text" className="form-control" value={this.state.id == "-1" ? "" : this.state.id} disabled />
                                </div>
                            </div>
                            <div className="col-md-9">
                                <div className="form-group">
                                    <label style={{ fontWeight: 'bold' }}>Email:</label>
                                    <input type="text" className="form-control" name="email" onChange={this.onChange} value={this.state.email} />
                                </div>
                            </div>
                        </div>

                        <div className="row" style={{ padding: 20 }}>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label style={{ fontWeight: 'bold' }}>Password:</label>
                                    <input type="password" className="form-control" name="password" onChange={this.onChange} value={this.state.password} />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label style={{ fontWeight: 'bold' }}>Admin:</label>
                                    <select onChange={this.onChange} className="form-control" name="admin" value={this.state.admin}>
                                        <option value="0" >No</option>
                                        <option value="1" >Si</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="row"><div className="col-md-4">&nbsp;</div></div>
                        <div className="row">
                            <div className="col-md-4"></div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <input type="submit" className="btn boton btn-primary"
                                        value={this.state.id == "-1" ? "Insertar" : "Modificar"} style={{ marginRight: 30, width: 150, height: 50 }} />
                                    <input type="button" className="btn boton-danger btn-danger" value="Cancel·lar" style={{ marginRight: 30, width: 150, height: 50 }}
                                        onClick={() => { window.location.assign("/llista_usuaris"); }} />
                                </div>
                            </div>
                        </div>
                    </form><br></br><br></br>
                </Container>
            </div>

        );
    }
}