import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Nav, Navbar, Col, Container, Row, NavDropdown, Button } from "react-bootstrap";
import SelectActius from '../funcionalitats/SelecActius';
import '../styles/listadosbdd.css';

export default class Edita_Fotografies extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: '',
            descripcio: '',
            url: '',
            id_actius: '',
            modificat: false
        }
    }

    onChange = (event) => {
        this.setState({ [event.target.name]: event.target.value, modificat: true });
    }

    componentDidMount() {
        if (this.props.id !== "-1") {
            this.descarrega(this.props.id);
        }
        this.setState({
            id: this.props.id
        });
    }


    componentDidUpdate(prevProps) {
        if (this.props.id !== prevProps.id) {
            if (this.props.id !== "-1") {
                this.descarrega(this.props.id);
            } else {
                this.setState({
                    id: this.props.id,
                    descripcio: '',
                    url: '',
                    id_actius: ''
                });
            }
        }
    }

    onSubmit = (e) => {
        e.preventDefault();
        if (this.state.id == "-1") {
            this.insertar();
        } else {
            this.modificar();
        }

    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onChangeActius = (v) => {
        this.setState({ id_actius: v, modificat: true });
    }



    descarrega(id) {
        var url = 'https://admin.europeanfundingproject.com/europeanfundingproject/public/api/fotografies/' + id;
        fetch(url, {
            method: 'GET',
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                'Authorization': 'Bearer ' + sessionStorage.getItem("token")
            },
        })
            .then(resposta => {
                return resposta.json()
            })
            .then(respostajson => {
                this.setState({
                    id: respostajson.id,
                    descripcio: respostajson.descripcio,
                    url: respostajson.url,
                    id_actius: respostajson.id_actius
                });
            })
            .catch(function (error) {
                console.log(error);
            })
    }


    insertar = () => {
        fetch('https://admin.europeanfundingproject.com/europeanfundingproject/public/api/fotografies', {
            method: 'POST',
            body: "descripcio=" + this.state.descripcio + "&url=" + this.state.url + "&id_actius=" + this.state.id_actius,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                'Authorization': 'Bearer ' + sessionStorage.getItem("token")
            },
        })
            .then(resposta => {
                return resposta.json()
            })
            .then(respostajson => {
                if (respostajson.status == "Creat") {
                    alert("Inserció realitzada correctament");
                    window.location.assign("/llista_fotografies");
                } else {
                    alert("Error creant");
                }
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    modificar = () => {
        fetch('https://admin.europeanfundingproject.com/europeanfundingproject/public/api/fotografies/' + this.state.id, {
            method: 'PUT',
            body: "descripcio=" + this.state.descripcio + "&url=" + this.state.url + "&id_actius=" + this.state.id_actius,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                'Authorization': 'Bearer ' + sessionStorage.getItem("token")
            },
        })
            .then(resposta => {
                return resposta.json()
            })
            .then(respostajson => {
                if (respostajson.status == "Modificat") {
                    alert("Modificació realitzada correctament");
                    window.location.assign("/llista_fotografies");
                } else {
                    alert("Error modificant");
                }
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    render() {
        return (
            <div>
                <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
                    <Container>
                        <Navbar.Brand href="/admin"><img className='logo' src='https://europeanfundingproject.com/wp-content/uploads/2022/05/EUROPEAN-FUNDING-PROJECT-logo-blanco.png'/></Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className='right'>
                                <Nav.Link href="/listadosbdd">Listados BDD</Nav.Link>
                                <Nav.Link href="/logout">Log Out</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
                <Container>
                    {this.state.id != -1 && <h1 style={{ marginTop: 50 }}>Editant la informació de la fotografia amb ID {this.state.id}</h1>}
                    {this.state.id == -1 && <h1 style={{ marginTop: 50 }}>Inserint una nova fotografia</h1>}
                    <form onSubmit={this.onSubmit}>
                        <div className="row" style={{ padding: 20 }}>
                            <div className="col-md-2">
                                <div className="form-group">
                                    <label style={{ fontWeight: 'bold' }}>Id:</label>
                                    <input type="text" className="form-control" value={this.state.id == "-1" ? "" : this.state.id} disabled />
                                </div>
                            </div>
                            <div className="col-md-10">
                                <div className="form-group">
                                    <label style={{ fontWeight: 'bold' }}>Descripció:</label>
                                    <input type="text" className="form-control" name="descripcio" onChange={this.onChange} value={this.state.descripcio} />
                                </div>
                            </div>

                        </div>

                        <div className="row" style={{ padding: 20 }}>
                            <div className="col-md-8">
                                <div className="form-group">
                                    <label style={{ fontWeight: 'bold' }}>URL:</label>
                                    <input type="text" className="form-control" name="url" onChange={this.onChange} value={this.state.url} />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className='form-group'>
                                    <label style={{ fontWeight: 'bold' }}>Actiu:</label>
                                    <SelectActius canviar={this.onChangeActius} valorinicial={this.state.id_actius}
                                        url="https://admin.europeanfundingproject.com/europeanfundingproject/public/api/actius"
                                        clau="id" display="id" />
                                </div>
                            </div>
                        </div>

                        <div className="row"><div className="col-md-4">&nbsp;</div></div>
                        <div className="row">
                            <div className="col-md-4"></div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <input type="submit" className="btn boton btn-primary"
                                        value={this.state.id == "-1" ? "Insertar" : "Modificar"} style={{ marginRight: 30, width: 150, height: 50 }} />
                                    <input type="button" className="btn boton-danger btn-danger" value="Cancel·lar" style={{ marginRight: 30, width: 150, height: 50 }}
                                        onClick={() => { window.location.assign("/llista_fotografies"); }} />
                                </div>
                            </div>
                        </div>
                    </form><br></br><br></br>
                </Container>
            </div>
        );
    }
}