import React, { Component } from 'react';
import { BrowserRouter, NavLink, Route, Routes, useParams } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Nav, Navbar, Col, Container, Row, NavDropdown, Button } from "react-bootstrap";
import '../styles/listadosbdd.css'
import {Bar} from 'react-chartjs-2';
import Chart from 'chart.js/auto';

export default class Vision_General extends Component {

    constructor(props) {
        super(props);

        this.state = {
            actius: [],
            descarregat: false
        }
    }

    componentDidMount() {
        this.descarrega();
    }

    descarrega = () => {
        fetch('https://admin.europeanfundingproject.com/europeanfundingproject/public/api/actius', {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.getItem("token"),
            },
        })
            .then(resposta => {
                return resposta.json()
            })
            .then(respostajson => {
                console.log(respostajson);
                this.setState({ actius: respostajson, descarregat: true });
            })
            .catch(function (error) {
                console.log(error);
            })
    }


    render() {

        if (!this.state.descarregat) {
            return <div>Decarregant visió general...</div>
        } else {

            let totalActius = 0;
            let hotel = 0;
            let hostal = 0;
            let aparthotel = 0;
            let suelo = 0;
            let parking = 0;
            let cadena = 0;
            let agroturismo = 0;
            let negocio = 0;
            let contador = 0;
            let contar = 0;
            let vivienda = 0;
            let local = 0;
            let pks = 0;
            let naveindustrial = 0;
            let chalet = 0;
            let adosado = 0;
            let Suelo = 0;
            let oficina = 0;
            let trastero = 0;


            {/*TOTAL ACTIUS*/}

            {
                this.state.actius.map((elemento) => {
                    totalActius++
                }
                )
                
            }

            {/*TOTAL HOTELES*/}

            {this.state.actius.map((elemento) => {
                if (elemento.tipus === 'Hotel') {
                    hotel++
                }
            }
            )}

            {/*TOTAL HOSTALES*/}

            {this.state.actius.map((elemento) => {
                if (elemento.tipus === 'Hostal') {
                    hostal++
                }
            }
            )}

            {/*TOTAL APARTHOTELES*/}

            {this.state.actius.map((elemento) => {
                if (elemento.tipus === 'Aparthotel') {
                    aparthotel++
                }
            }
            )}

            {/*TOTAL SUELOS*/}

            {this.state.actius.map((elemento) => {
                if (elemento.tipus === 'Suelo') {
                    suelo++
                }
            }
            )}

            {/*TOTAL PARKINGS*/}

            {this.state.actius.map((elemento) => {
                if (elemento.tipus === 'Parking') {
                    parking++
                }
            }
            )}

            {/*TOTAL CADENAS*/}

            {this.state.actius.map((elemento) => {
                if (elemento.tipus === 'Cadena') {
                    cadena++
                }
            }
            )}

            {/*TOTAL AGROTURISMOS*/}

            {this.state.actius.map((elemento) => {
                if (elemento.tipus === 'Agroturismo') {
                    agroturismo++
                }
            }
            )}

            {/*TOTAL NEGOCIOS*/}

            {this.state.actius.map((elemento) => {
                if (elemento.tipus === 'Negocio') {
                    negocio++
                }
            }
            )}

            {/*TOTAL NPLs, REOs y WIPs*/}

            {this.state.actius.map((elemento) => {
                if (elemento.tipus === 'CD' || elemento.tipus === 'WIP') {
                    contador++
                }
            }
            )}

            {this.state.actius.map((elemento) => {
                if (elemento.tipus === 'CD' || elemento.tipus === 'WIP') {
                    contar++
                }
            }
            )}

            {/*TOTAL VIVIENDAS*/}

            {this.state.actius.map((elemento) => {
                if (elemento.subtipus === 'Vivienda') {
                    vivienda++
                }
            }
            )}

            {/*TOTAL LOCALES*/}

            {this.state.actius.map((elemento) => {
                if (elemento.subtipus === 'Local') {
                    local++
                }
            }
            )}

            {/*TOTAL PARKINGS*/}

            {this.state.actius.map((elemento) => {
                if (elemento.subtipus === 'Parking') {
                    pks++
                }
            }
            )}

            {/*TOTAL NAVE INDUSTRIALES*/}

            {this.state.actius.map((elemento) => {
                if (elemento.subtipus === 'Nave Industrial') {
                    naveindustrial++
                }
            }
            )}
            
            {/*TOTAL CHALETS*/}

            {this.state.actius.map((elemento) => {
                if (elemento.subtipus === 'Chalet') {
                    chalet++
                }
            }
            )}

            {/*TOTAL ADOSADOS*/}

            {this.state.actius.map((elemento) => {
                if (elemento.subtipus === 'Adosado') {
                    adosado++
                }
            }
            )}

            {/*TOTAL Suelo*/}

            {this.state.actius.map((elemento) => {
                if (elemento.subtipus === 'Suelo') {
                    Suelo++
                }
            }
            )}

            {/*TOTAL OFICINAS*/}

            {this.state.actius.map((elemento) => {
                if (elemento.subtipus === 'Oficina') {
                    oficina++
                }
            }
            )}

            {/*TOTAL TRASTEROS*/}

            {this.state.actius.map((elemento) => {
                if (elemento.subtipus === 'Trastero') {
                    trastero++
                }
            }
            )}


            const data = {
                labels: ['Hotel', 'Hostal', 'Aparthotel', 'Suelo', 'Parking', 'Cadena', 'Agroturismo', 'Negocio', 'NPLs, REOs y WIPs'],
                datasets: [{
                    label: 'Actius',
                    backgroundColor: 'rgb(34, 29, 17)',
                    borderColor: 'black',
                    borderwidth: 2,
                    hoverBackgroundColor: 'rgb(85, 72, 43)',
                    hoverBorderColor: '#FF0000',
                    data: [hotel, hostal, aparthotel, suelo, parking, cadena, agroturismo, negocio, contador]
                }]
            };

            const opciones = {
                maintainAspectRatio: false,
                responsive: true
            }

            const datos = {
                labels: ['Vivienda', 'Local', 'Parking', 'Nave Industrial', 'Chalet', 'Adosado', 'Suelo', 'Oficina', 'Trastero'],
                datasets: [{
                    label: 'Actius',
                    backgroundColor: 'rgb(34, 29, 17)',
                    borderColor: 'black',
                    borderwidth: 2,
                    hoverBackgroundColor: 'rgb(85, 72, 43)',
                    hoverBorderColor: '#FF0000',
                    data: [vivienda, local, pks, naveindustrial, chalet, adosado, Suelo, oficina, trastero]
                }]
            };

            const opcions = {
                maintainAspectRatio: false,
                responsive: true
            }

            return (

                <body>
                    <div>
                        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
                            <Container>
                                <Navbar.Brand href="/admin"><img className='logo' src='https://europeanfundingproject.com/wp-content/uploads/2022/05/EUROPEAN-FUNDING-PROJECT-logo-blanco.png' /></Navbar.Brand>
                                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                                <Navbar.Collapse id="responsive-navbar-nav">
                                    <Nav className='right'>
                                        <Nav.Link href="/admin">Admin</Nav.Link>
                                        <Nav.Link href="/logout">Log Out</Nav.Link>
                                    </Nav>
                                </Navbar.Collapse>
                            </Container>
                        </Navbar>

                        <div className='container' >

                            {/*TOTAL ACTIUS*/}

                            <div className='w-75 mt-5 mb-5 divAdmin'>

                                {<p>Total actius: {totalActius}</p>}

                            </div>

                            <div className='App graficos'>
                                <Bar data={data} options={opciones}/>
                            </div>

                            {/*TOTAL NPLs, REOs y WIPs*/}

                            <div className='w-75 mt-5 mb-5 divAdmin'>

                                {<p>NPLs, REOs y WIPs: {contar}</p>}

                            </div>

                            <div className='App graficos mb-5'>
                                <Bar data={datos} options={opcions}/>
                            </div>
                        </div>
                    </div>
                </body>
            );
        }
    }
}