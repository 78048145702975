import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Nav, Navbar, Col, Container, Row, NavDropdown, Button } from "react-bootstrap";

export default class Edita_Tipus extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: '',
            nom: '',
            subtipus: '',
            modificat: false
        }
    }

    onChange = (event) => {
        this.setState({ [event.target.name]: event.target.value, modificat: true });
    }

    componentDidMount() {
        if (this.props.id !== "-1") {
            this.descarrega(this.props.id);
        }
        this.setState({
            id: this.props.id
        });
    }


    componentDidUpdate(prevProps) {
        if (this.props.id !== prevProps.id) {
            if (this.props.id !== "-1") {
                this.descarrega(this.props.id);
            } else {
                this.setState({
                    id: this.props.id,
                    nom: '',
                    subtipus: ''
                });
            }
        }
    }

    onSubmit = (e) => {
        e.preventDefault();
        if (this.state.id == "-1") {
            this.insertar();
        } else {
            this.modificar();
        }

    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }


    descarrega(id) {
        var url = 'http://localhost/unikInmobiliaria/public/api/tipus/' + id;
        fetch(url, {
            method: 'GET',
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                'Authorization': 'Bearer ' + sessionStorage.getItem("token")
            },
        })
            .then(resposta => {
                return resposta.json()
            })
            .then(respostajson => {
                this.setState({
                    id: respostajson.id,
                    nom: respostajson.nom,
                    subtipus: respostajson.subtipus
                });
            })
            .catch(function (error) {
                console.log(error);
            })
    }


    insertar = () => {
        fetch('http://localhost/unikInmobiliaria/public/api/tipus', {
            method: 'POST',
            body: "nom=" + this.state.nom + "&subtipus=" + this.state.subtipus,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                'Authorization': 'Bearer ' + sessionStorage.getItem("token")
            },
        })
            .then(resposta => {
                return resposta.json()
            })
            .then(respostajson => {
                if (respostajson.status == "Creat") {
                    alert("Inserció realitzada correctament");
                    window.location.assign("/llista_tipus");
                } else {
                    alert("Error creant");
                }
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    modificar = () => {
        fetch('http://localhost/unikInmobiliaria/public/api/tipus/' + this.state.id, {
            method: 'PUT',
            body: "nom=" + this.state.nom + "&subtipus=" + this.state.subtipus,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                'Authorization': 'Bearer ' + sessionStorage.getItem("token")
            },
        })
            .then(resposta => {
                return resposta.json()
            })
            .then(respostajson => {
                if (respostajson.status == "Modificat") {
                    alert("Modificació realitzada correctament");
                    window.location.assign("/llista_tipus");
                } else {
                    alert("Error modificant");
                }
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    render() {
        return (
            <div>
                <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
                    <Container>
                        <Navbar.Brand href="/admin">UNIK INMOBILIARIA</Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className='right'>
                                <Nav.Link href="/listadosbdd">Listados BDD</Nav.Link>
                                <Nav.Link href="/logout">Log Out</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
                <Container>
                    {this.state.id != -1 && <h1 style={{ marginTop: 50, marginBottom: 50}}>Editant la informació del tipus amb ID {this.state.id}</h1>}
                    {this.state.id == -1 && <h1 style={{ marginTop: 50, marginBottom: 50 }}>Inserint un nou tipus</h1>}
                    <form onSubmit={this.onSubmit}>
                        <div className="row" style={{ padding: 20 }}>
                            <div className="col-md-2">
                                <div className="form-group">
                                    <label style={{ fontWeight: 'bold' }}>Id:</label>
                                    <input type="text" className="form-control" value={this.state.id == "-1" ? "" : this.state.id} disabled />
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="form-group">
                                    <label style={{ fontWeight: 'bold' }}>Nom:</label>
                                    <input type="text" className="form-control" name="nom" onChange={this.onChange} value={this.state.nom} />
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="form-group">
                                    <label style={{ fontWeight: 'bold' }}>Subtipus:</label>
                                    <input type="text" className="form-control" name="subtipus" onChange={this.onChange} value={this.state.subtipus} />
                                </div>
                            </div>
                        </div>

                        <div className="row"><div className="col-md-4">&nbsp;</div></div>
                        <div className="row">
                            <div className="col-md-4"></div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <input type="submit" className="btn btn-primary"
                                        value={this.state.id == "-1" ? "Insertar" : "Modificar"} style={{ marginRight: 30, width: 150, height: 50 }} />
                                    <input type="button" className="btn btn-danger" value="Cancel·lar" style={{ marginRight: 30, width: 150, height: 50 }}
                                        onClick={() => { window.location.assign("/llista_tipus"); }} />
                                </div>
                            </div>
                        </div>
                    </form><br></br><br></br>
                </Container>
            </div>

        );
    }
}