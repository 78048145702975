import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AgGridReact } from 'ag-grid-react';
import './llistats.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { Nav, Navbar, Col, Container, Row, NavDropdown, Button } from "react-bootstrap";

export default class Llista_Tipus extends Component {
    constructor(props) {
        super(props);

        const pintabotoedita = (params) => {
            return <div>
                <Button color="primary" size="lg"
                    onClick={() => { window.location.assign("/edita_tipus/" + params.data.id); }}>
                    Editar</Button>
            </div>
        }

        const pintabotoesborra = (params) => {
            return <div>
                <Button variant="danger" size="lg"
                    onClick={() => { this.delete(params.data.id) }}>
                    Esborrar</Button>
            </div>
        }

        this.state = {
            tipus: [],
            columnes: [
                { field: 'id', headerName: 'ID', maxWidth: 120, sortable: true },
                { field: 'nom', headerName: 'Nom', minWidth: 300, filter: true },
                { field: 'subtipus', headerName: 'Subtipus', minWidth: 250, sortable: true },
                { field: '', cellRendererFramework: pintabotoedita, maxWidth: 150 },
                { field: '', cellRendererFramework: pintabotoesborra, maxWidth: 150 },
            ],
            descarregat: false
        }
    }

    delete(id) {
        let borrar = window.confirm("Estás segur de que vols borrar el tipus?");
        if (borrar) {
            fetch('http://localhost/unikInmobiliaria/public/api/tipus/' + id, {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + sessionStorage.getItem("token")
                },
            })
                .then(resposta => {
                    return resposta.json()
                })
                .then(respostajson => {
                    if (respostajson.status == id + " Borrat Correctament") {
                        alert("S'ha eliminat el tipus");
                        this.descarrega();
                    } else {
                        alert("Error esborrant");
                    }
                })
                .catch(function (error) {
                    console.log(error);
                })
        }
    }

    componentDidMount() {
        this.descarrega();
    }

    descarrega = () => {
        fetch('http://localhost/unikInmobiliaria/public/api/tipus', {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.getItem("token"),
            },
        })
            .then(resposta => {
                return resposta.json()
            })
            .then(respostajson => {
                console.log(respostajson);
                this.setState({ tipus: respostajson, descarregat: true });
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    render() {
        if (!this.state.descarregat) {
            return <div>Decarregant tipus...</div>
        } else {
            return (
                <div>
                    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
                        <Container>
                            <Navbar.Brand href="/admin">UNIK INMOBILIARIA</Navbar.Brand>
                            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                            <Navbar.Collapse id="responsive-navbar-nav">
                                <Nav className='right'>
                                    <Nav.Link href="/listadosbdd">Listados BDD</Nav.Link>
                                    <Nav.Link href="/logout">Log Out</Nav.Link>
                                </Nav>
                            </Navbar.Collapse>
                        </Container>
                    </Navbar>
                    <Container style={{ fontSize: 25, marginTop: 50 }}>
                        <Row>
                            <h1>Tipus</h1>
                        </Row>
                    </Container>

                    <div style={{ marginTop: 50 }}>
                        <Button style={{ fontSize: 30 }} className="btn btn-primary"
                            onClick={() => { window.location.assign("/edita_tipus/-1"); }}>Insertar un nou tipus</Button>
                    </div>

                    <div className="ag-theme-alpine tipus" style={{
                        height: 830, margin: 'auto',
                        marginTop: 50, marginBottom: 50, fontSize: 25
                    }}>
                        <AgGridReact
                            rowData={this.state.tipus}
                            columnDefs={this.state.columnes}
                            pagination={true}
                            paginationPageSize={10}
                            rowHeight={70}>
                        </AgGridReact>
                    </div>
                </div>
            );
        }
    }
}