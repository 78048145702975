import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Nav, Navbar, Col, Container, Row, NavDropdown, Button } from "react-bootstrap";
import Select from '../funcionalitats/Select';
import '../styles/listadosbdd.css';

export default class Edita_Actius extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: '',
            titol: '',
            tipus: '',
            subtipus: '',
            descripcio: '',
            categoria: '',
            habitacions: '',
            tamany: '',
            preu: '',
            pais: '',
            comunitat: '',
            municipi: '',
            direccio: '',
            origen: '',
            habilitat: '',
            id_usuaris: '',
            email: '',
            referencia: '',
            comisio: '',
            catastre: '',
            contacte: '',
            unitats: '',
            modificat: false
        }
    }

    onChange = (event) => {
        this.setState({ [event.target.name]: event.target.value, modificat: true });
    }

    componentDidMount() {
        if (this.props.id !== "-1") {
            this.descarrega(this.props.id);
        }
        this.setState({
            id: this.props.id,
            habilitat: '1'
        });
    }


    componentDidUpdate(prevProps) {
        if (this.props.id !== prevProps.id) {
            if (this.props.id !== "-1") {
                this.descarrega(this.props.id);
            } else {
                this.setState({
                    id: this.props.id,
                    titol: '',
                    tipus: '',
                    subtipus: '',
                    descripcio: '',
                    categoria: '',
                    habitacions: '',
                    tamany: '',
                    preu: '',
                    pais: '',
                    comunitat: '',
                    municipi: '',
                    direccio: '',
                    origen: '',
                    habilitat: '',
                    id_usuaris: '',
                    email: '',
                    referencia: '',
                    comisio: '',
                    catastre: '',
                    contacte: '',
                    unitats: ''
                });
            }
        }
    }

    onSubmit = (e) => {
        e.preventDefault();
        if (this.state.id == "-1") {
            this.insertar();
        } else {
            this.modificar();
        }

    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }



    descarrega(id) {
        var url = 'https://admin.europeanfundingproject.com/europeanfundingproject/public/api/actius/' + id;
        fetch(url, {
            method: 'GET',
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                'Authorization': 'Bearer ' + sessionStorage.getItem("token")
            },
        })
            .then(resposta => {
                return resposta.json()
            })
            .then(respostajson => {
                this.setState({
                    id: respostajson.id,
                    titol: respostajson.titol ? respostajson.titol : '',
                    tipus: respostajson.tipus ? respostajson.tipus : '',
                    subtipus: respostajson.subtipus ? respostajson.subtipus : '',
                    descripcio: respostajson.descripcio ? respostajson.descripcio : '',
                    categoria: respostajson.categoria ? respostajson.categoria : '',
                    habitacions: respostajson.habitacions ? respostajson.habitacions : '',
                    tamany: respostajson.tamany ? respostajson.tamany : '',
                    preu: respostajson.preu ? respostajson.preu : '',
                    pais: respostajson.pais ? respostajson.pais : '',
                    comunitat: respostajson.comunitat ? respostajson.comunitat : '',
                    municipi: respostajson.municipi ? respostajson.municipi : '',
                    direccio: respostajson.direccio ? respostajson.direccio : '',
                    origen: respostajson.origen ? respostajson.origen : '',
                    habilitat: respostajson.habilitat ? respostajson.habilitat : '',
                    id_usuaris: respostajson.usuaris.id,
                    email: respostajson.usuaris.email,
                    referencia: respostajson.referencia ? respostajson.referencia : '',
                    comisio: respostajson.comisio ? respostajson.comisio : '',
                    catastre: respostajson.catastre ? respostajson.catastre : '',
                    contacte: respostajson.contacte ? respostajson.contacte : '',
                    unitats: respostajson.unitats ? respostajson.unitats : ''
                });
            })
            .catch(function (error) {
                console.log(error);
            })
    }


    insertar = () => {
        fetch('https://admin.europeanfundingproject.com/europeanfundingproject/public/api/actius', {
            method: 'POST',
            body: "titol=" + this.state.titol + "&descripcio=" + this.state.descripcio + "&categoria=" + this.state.categoria
                + "&habitacions=" + this.state.habitacions + "&tamany=" + this.state.tamany + "&preu=" + this.state.preu
                + "&pais=" + this.state.pais + "&comunitat=" + this.state.comunitat + "&municipi=" + this.state.municipi
                + "&direccio=" + this.state.direccio + "&origen=" + this.state.origen + "&habilitat=" + this.state.habilitat
                + "&id_usuaris=" + sessionStorage.getItem('id') + "&referencia=" + this.state.referencia
                + "&tipus=" + this.state.tipus + "&subtipus=" + this.state.subtipus + "&comisio=" + this.state.comisio
                + "&catastre=" + this.state.catastre + "&contacte=" + this.state.contacte + "&unitats=" + this.state.unitats,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                'Authorization': 'Bearer ' + sessionStorage.getItem("token")
            },
        })
            .then(resposta => {
                return resposta.json()
            })
            .then(respostajson => {
                if (respostajson.status == "Creat") {
                    alert("Inserció realitzada correctament");
                    window.location.assign("/llista_actius");
                } else {
                    alert("Error creant");
                }
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    modificar = () => {
        console.log(this.state);
        fetch('https://admin.europeanfundingproject.com/europeanfundingproject/public/api/actius/' + this.state.id, {
            method: 'PUT',
            body: "titol=" + this.state.titol + "&descripcio=" + this.state.descripcio + "&categoria=" + this.state.categoria
                + "&habitacions=" + this.state.habitacions + "&tamany=" + this.state.tamany + "&preu=" + this.state.preu
                + "&pais=" + this.state.pais + "&comunitat=" + this.state.comunitat + "&municipi=" + this.state.municipi
                + "&direccio=" + this.state.direccio + "&origen=" + this.state.origen + "&habilitat=" + this.state.habilitat
                + "&id_usuaris=" + this.state.id_usuaris + "&referencia=" + this.state.referencia
                + "&tipus=" + this.state.tipus + "&subtipus=" + this.state.subtipus + "&comisio=" + this.state.comisio
                + "&catastre=" + this.state.catastre + "&contacte=" + this.state.contacte + "&unitats=" + this.state.unitats,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                'Authorization': 'Bearer ' + sessionStorage.getItem("token")
            },
        })
            .then(resposta => {
                return resposta.json()
            })
            .then(respostajson => {
                console.log(respostajson);
                if (respostajson.status == "Modificat") {
                    alert("Modificació realitzada correctament");
                    window.location.assign("/llista_actius");
                } else {
                    alert("Error modificant");
                }
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    render() {
        return (
            <div>
                <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
                    <Container>
                        <Navbar.Brand href="/admin"><img className='logo' src='https://europeanfundingproject.com/wp-content/uploads/2022/05/EUROPEAN-FUNDING-PROJECT-logo-blanco.png'/></Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className='right'>
                                <Nav.Link href="/listadosbdd">Listados BDD</Nav.Link>
                                <Nav.Link href="/logout">Log Out</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
                <Container>
                    {this.state.id != -1 && <h1 style={{ marginTop: 50 }}>Editant la informació de l'actiu amb ID {this.state.id}</h1>}
                    {this.state.id == -1 && <h1 style={{ marginTop: 50 }}>Inserint un nou actiu</h1>}
                    <form onSubmit={this.onSubmit}>
                        <div className="row" style={{ padding: 20 }}>
                            <div className="col-md-2">
                                <div className="form-group">
                                    <label style={{ fontWeight: 'bold' }}>Id:</label>
                                    <input type="text" className="form-control" value={this.state.id == "-1" ? "" : this.state.id} disabled />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label style={{ fontWeight: 'bold' }}>Titol:</label>
                                    <input type="text" className="form-control" name="titol" onChange={this.onChange} value={this.state.titol} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label style={{ fontWeight: 'bold' }}>Tipus:</label>
                                    <input type="text" className="form-control" name="tipus" onChange={this.onChange} value={this.state.tipus} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label style={{ fontWeight: 'bold' }}>Subtipus:</label>
                                    <input type="text" className="form-control" name="subtipus" onChange={this.onChange} value={this.state.subtipus} />
                                </div>
                            </div>
                        </div>

                        <div className="row" style={{ padding: 20 }}>
                            <div className="col-md-5">
                                <div className="form-group">
                                    <label style={{ fontWeight: 'bold' }}>Descripció:</label>
                                    <textarea rows="1" className="form-control" name="descripcio" onChange={this.onChange} value={this.state.descripcio} />
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="form-group">
                                    <label style={{ fontWeight: 'bold' }}>Categoria:</label>
                                    <input type="text" className="form-control" name="categoria" onChange={this.onChange} value={this.state.categoria} />
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="form-group">
                                    <label style={{ fontWeight: 'bold' }}>Unitats:</label>
                                    <input type="text" className="form-control" name="unitats" onChange={this.onChange} value={this.state.unitats} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label style={{ fontWeight: 'bold' }}>Referencia:</label>
                                    <input type="text" className="form-control" name="referencia" onChange={this.onChange} value={this.state.referencia} />
                                </div>
                            </div>
                        </div>

                        <div className="row" style={{ padding: 20 }}>
                            <div className="col-md-2">
                                <div className="form-group">
                                    <label style={{ fontWeight: 'bold' }}>Habitacions:</label>
                                    <input type="text" className="form-control" name="habitacions" onChange={this.onChange} value={this.state.habitacions} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label style={{ fontWeight: 'bold' }}>Tamany(m2):</label>
                                    <input type="text" className="form-control" name="tamany" onChange={this.onChange} value={this.state.tamany} />
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="form-group">
                                    <label style={{ fontWeight: 'bold' }}>Preu (€):</label>
                                    <input type="text" className="form-control" name="preu" onChange={this.onChange} value={this.state.preu} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label style={{ fontWeight: 'bold' }}>Origen:</label>
                                    <input type="text" className="form-control" name="origen" onChange={this.onChange} value={this.state.origen} />
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="form-group">
                                    <label style={{ fontWeight: 'bold' }}>Contacte:</label>
                                    <input type="text" className="form-control" name="contacte" onChange={this.onChange} value={this.state.contacte} />
                                </div>
                            </div>
                        </div>

                        <div className="row" style={{ padding: 20 }}>
                            <div className="col-md-2">
                                <div className="form-group">
                                    <label style={{ fontWeight: 'bold' }}>Pais:</label>
                                    <input type="text" className="form-control" name="pais" onChange={this.onChange} value={this.state.pais} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label style={{ fontWeight: 'bold' }}>Comunitat:</label>
                                    <input type="text" className="form-control" name="comunitat" onChange={this.onChange} value={this.state.comunitat} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label style={{ fontWeight: 'bold' }}>Municipi:</label>
                                    <input type="text" className="form-control" name="municipi" onChange={this.onChange} value={this.state.municipi} />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label style={{ fontWeight: 'bold' }}>Direccio:</label>
                                    <input type="text" className="form-control" name="direccio" onChange={this.onChange} value={this.state.direccio} />
                                </div>
                            </div>
                        </div>

                        <div className="row" style={{ padding: 20 }}>
                            <div className="col-md-2">
                                <div className="form-group">
                                    <label style={{ fontWeight: 'bold' }}>Comisio:</label>
                                    <input type="text" className="form-control" name="comisio" onChange={this.onChange} value={this.state.comisio} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label style={{ fontWeight: 'bold' }}>Catastre:</label>
                                    <input type="text" className="form-control" name="catastre" onChange={this.onChange} value={this.state.catastre} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label style={{ fontWeight: 'bold' }}>Habilitat:</label>
                                    <select onChange={this.onChange} className="form-control" name="habilitat" value={this.state.habilitat}>
                                        <option value="1" >Si</option>
                                        <option value="0" >No</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className='form-group'>
                                    <label style={{ fontWeight: 'bold' }}>Usuari:</label>
                                    <input type="text" className="form-control" value={this.state.id == "-1" ? sessionStorage.getItem('email') : this.state.email} disabled />
                                    <input type="hidden" className="form-control" value={this.state.id == "-1" ? sessionStorage.getItem('id') : this.state.id_usuaris}
                                        onChange={this.onChange} name="id_usuaris" />
                                </div>
                            </div>
                        </div>

                        <div className="row"><div className="col-md-4">&nbsp;</div></div>
                        <div className="row">
                            <div className="col-md-4"></div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <input type="submit" className="btn btn-primary boton"
                                        value={this.state.id == "-1" ? "Insertar" : "Modificar"} style={{ marginRight: 30, width: 150, height: 50 }} />
                                    <input type="button" className="btn btn-danger boton-danger" value="Cancel·lar" style={{ marginRight: 30, width: 150, height: 50 }}
                                        onClick={() => { window.location.assign("/llista_actius"); }} />
                                </div>
                            </div>
                        </div>
                    </form><br></br><br></br>
                </Container>
            </div>

        );
    }
}

