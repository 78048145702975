import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AgGridReact } from 'ag-grid-react';
import './llistats.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { Nav, Navbar, Col, Container, Row, NavDropdown, Button } from "react-bootstrap";
import '../styles/listadosbdd.css';

export default class Llista_Usuaris extends Component {
    constructor(props) {
        super(props);

        const pintabotoedita = (params) => {
            return <div>
                <Button className='boton' color="primary" size="lg"
                    onClick={() => { window.location.assign("/edita_usuari/" + params.data.id); }}>
                    Editar</Button>
            </div>
        }

        const pintabotoesborra = (params) => {
            return <div>
                <Button className='boton-danger' variant="danger" size="lg"
                    onClick={() => { this.delete(params.data.id) }}>
                    Esborrar</Button>
            </div>
        }

        this.state = {
            usuaris: [],
            columnes: [
                { field: 'id', headerName: 'ID', maxWidth: 120, sortable: true },
                { field: 'email', headerName: 'Email', minWidth: 500, filter: true, resizable: true, editable: true },
                { field: 'admin', headerName: 'Admin', minWidth: 100, sortable: true },
                { field: '', cellRendererFramework: pintabotoedita, maxWidth: 150 },
                { field: '', cellRendererFramework: pintabotoesborra, maxWidth: 150 },
            ],
            descarregat: false
        }
    }


    componentDidMount() {
        this.descarrega();
    }

    descarrega = () => {
        fetch('https://admin.europeanfundingproject.com/europeanfundingproject/public/api/usuaris', {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.getItem("token"),
            },
        })
            .then(resposta => {
                return resposta.json()
            })
            .then(respostajson => {
                console.log(respostajson);
                this.setState({ usuaris: respostajson, descarregat: true });
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    delete(id) {
        let borrar = window.confirm("Estás segur de que vols borrar l'usuari?");
        if (borrar) {
            fetch('https://admin.europeanfundingproject.com/europeanfundingproject/public/api/usuaris/' + id, {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + sessionStorage.getItem("token")
                },
            })
                .then(resposta => {
                    return resposta.json()
                })
                .then(respostajson => {
                    if (respostajson.status == id + " Borrat Correctament") {
                        alert("S'ha eliminat l'usuari");
                        this.descarrega();
                    } else {
                        alert("Error esborrant");
                    }
                })
                .catch(function (error) {
                    console.log(error);
                })
        }
    }

    render() {
        if (!this.state.descarregat) {
            return <div>Decarregant usuaris...</div>
        } else {
            return (
                <div>
                    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
                        <Container>
                            <Navbar.Brand href="/admin"><img className='logo' src='https://europeanfundingproject.com/wp-content/uploads/2022/05/EUROPEAN-FUNDING-PROJECT-logo-blanco.png'/></Navbar.Brand>
                            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                            <Navbar.Collapse id="responsive-navbar-nav">
                                <Nav className='right'>
                                    <Nav.Link href="/listadosbdd">Listados BDD</Nav.Link>
                                    <Nav.Link href="/logout">Log Out</Nav.Link>
                                </Nav>
                            </Navbar.Collapse>
                        </Container>
                    </Navbar>
                    <Container style={{ fontSize: 25, marginTop: 50 }}>
                        <Row>
                            <h1>Usuaris</h1>
                        </Row>
                    </Container>

                    <div style={{ marginTop: 50 }}>
                        <Button style={{ fontSize: 30 }} className="btn boton btn-primary"
                            onClick={() => { window.location.assign("/edita_usuari/-1"); }}>Insertar un nou usuari</Button>
                    </div>

                    <div className="ag-theme-alpine usuaris" style={{
                        height: 830, margin: 'auto',
                        marginTop: 50, marginBottom: 50, fontSize: 15
                    }}>
                        <AgGridReact
                            rowData={this.state.usuaris}
                            columnDefs={this.state.columnes}
                            pagination={true}
                            paginationPageSize={10}
                            rowHeight={70}>
                        </AgGridReact>
                    </div>
                </div>
            );
        }
    }
}