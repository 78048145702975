import React, { Component } from 'react';
import { BrowserRouter, NavLink, Route, Routes, useParams } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Nav, Navbar, Col, Container, Row, NavDropdown, Button } from "react-bootstrap";
import './styles/admin.css'

export default class Admin extends Component {
    render() {
        return (
            <body>
                <div>
                <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
                    <Container >
                        <Navbar.Brand href="/admin"><img className='logo' src='https://europeanfundingproject.com/wp-content/uploads/2022/05/EUROPEAN-FUNDING-PROJECT-logo-blanco.png'/></Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className='right'>
                                <Nav.Link href="/logout">Log Out</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
                <div style={{  margin: 50}}>
                    <Row className='center-text'>
                        <h1>Bienvenido</h1>
                        <p className='email'>{sessionStorage.getItem("email")}</p>
                        <h2>Que te gustaría hacer?</h2>
                    </Row>
                </div>
                <div className='container' >
                    <div className='w-75 mt-5 mb-5 divAdmin'>
                        <div className='row mt-3'>
                            <div className='col-md-8 titulosAdmin'><span>Listados BDD</span></div>
                            <div className='col-md-4'><Button className="btn boton w-50" onClick={() => { window.location.assign("/listadosbdd"); }}>Ir!</Button></div>
                        </div>

                        <div className='row mt-3 mb-3'>
                            <div className='col-md-8 titulosAdmin'><span>Visión general</span></div>
                            <div className='col-md-4'><Button className="btn boton w-50" onClick={() => { window.location.assign("/visiongeneral"); }}>Ir!</Button></div>
                        </div>
                    </div>
                </div>
            </div>

            </body>
            
        );
    }
}
