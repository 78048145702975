import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { Nav, Navbar, Col, Container, Row, NavDropdown, Button } from "react-bootstrap";
import './llistats.css';
import '../styles/listadosbdd.css';

export default class Llista_Actius extends Component {
    constructor(props) {
        super(props);

        const pintabotoedita = (params) => {
            return <div>
                <Button className='boton' size="lg"
                    onClick={() => { window.location.assign("/edita_actius/" + params.data.id); }}>
                    Editar</Button>
            </div>
        }

        const pintabotoesborra = (params) => {
            return <div>
                <Button className='boton-danger' variant='danger' size="lg"
                    onClick={() => { this.delete(params.data.id) }}>
                    Esborrar</Button>
            </div>
        }

        this.state = {
            actius: [],
            columnes: [
                { field: 'id', headerName: 'ID', maxWidth: 100, sortable: true },
                { field: 'referencia', headerName: 'Referencia', minWidth: 100, filter: true, editable: true },
                { field: 'titol', headerName: 'Titol', minWidth: 350, filter: true, resizable: true, editable: true },
                { field: 'tipus', headerName: 'Tipus', minWidth: 130, filter: true, editable: true },
                { field: 'subtipus', headerName: 'Subtipus', minWidth: 250, filter: true, editable: true },
                { field: 'descripcio', headerName: 'Descripció', minWidth: 450, filter: true, resizable: true, editable: true },
                { field: 'categoria', headerName: 'Categoria', minWidth: 150, filter: true, sortable: true, editable: true },
                { field: 'habitacions', headerName: 'Habitacions', minWidth: 150, sortable: true, editable: true },
                { field: 'tamany', headerName: 'Tamany (m2)', minWidth: 250, sortable: true, editable: true },
                { field: 'preu', headerName: 'Preu (€)', minWidth: 150, sortable: true, editable: true },
                { field: 'pais', headerName: 'Pais', minWidth: 200, filter: true, editable: true },
                { field: 'comunitat', headerName: 'Comunitat', minWidth: 250, filter: true, editable: true },
                { field: 'municipi', headerName: 'Municipi', minWidth: 250, filter: true, editable: true },
                { field: 'direccio', headerName: 'Direccio', minWidth: 280, filter: true, editable: true },
                { field: 'origen', headerName: 'Origen', minWidth: 250, filter: true, editable: true },
                { field: 'habilitat', headerName: 'Habilitat', minWidth: 100, sortable: true },
                { field: 'comisio', headerName: 'Comisio', minWidth: 100, sortable: true, filter: true, editable: true },
                { field: 'catastre', headerName: 'Catastre', minWidth: 150, filter: true, editable: true },
                { field: 'contacte', headerName: 'Contacte', minWidth: 150, filter: true, editable: true },
                { field: 'unitats', headerName: 'Unitats', minWidth: 100, sortable: true, filter: true, editable: true },
                { field: 'usuaris.email', headerName: 'Usuari', minWidth: 420, filter: true, resizable: true, editable: true },
                { field: '', cellRendererFramework: pintabotoedita, maxWidth: 150 },
                { field: '', cellRendererFramework: pintabotoesborra, maxWidth: 150 },
            ],
            descarregat: false
        }
    }


    componentDidMount() {
        this.descarrega();
    }

    descarrega = () => {
        fetch('https://admin.europeanfundingproject.com/europeanfundingproject/public/api/actius', {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.getItem("token"),
            },
        })
            .then(resposta => {
                return resposta.json()
            })
            .then(respostajson => {
                this.setState({ actius: respostajson, descarregat: true });
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    delete(id) {
        let borrar = window.confirm("Estás segur de que vols borrar l'actiu?");
        if (borrar) {
            fetch('https://admin.europeanfundingproject.com/europeanfundingproject/public/api/actius/' + id, {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + sessionStorage.getItem("token")
                },
            })
                .then(resposta => {
                    return resposta.json()
                })
                .then(respostajson => {
                    if (respostajson.status == id + " Borrat Correctament") {
                        alert("S'ha eliminat l'actiu");
                        this.descarrega();
                    } else {
                        alert("Error esborrant");
                    }
                })
                .catch(function (error) {
                    console.log(error);
                })
        }
    }

    render() {

        const getRowStyle = params => {
            if (params.data.habilitat === 0) {
                return { backgroundColor: '#ff8080' };
            }
        }


        if (!this.state.descarregat) {
            return <div>Decarregant actius...</div>
        } else {
            return (
                
                <div>
                    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
                        <Container>
                            <Navbar.Brand href="/admin"><img className='logo' src='https://europeanfundingproject.com/wp-content/uploads/2022/05/EUROPEAN-FUNDING-PROJECT-logo-blanco.png'/></Navbar.Brand>
                            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                            <Navbar.Collapse id="responsive-navbar-nav">
                                <Nav className='right'>
                                    <Nav.Link href="/listadosbdd">Listados BDD</Nav.Link>
                                    <Nav.Link href="/logout">Log Out</Nav.Link>
                                </Nav>
                            </Navbar.Collapse>
                        </Container>
                    </Navbar>
                    <Container style={{ fontSize: 25, marginTop: 50 }}>
                        <Row>
                            <h1>Actius</h1>
                        </Row>
                    </Container>

                    <div style={{ marginTop: 50 }}>
                        <Button style={{ fontSize: 30 }} className="btn boton"
                            onClick={() => { window.location.assign("/edita_actius/-1"); }}>Insertar un nou actiu</Button>
                    </div>

                    <div className="ag-theme-alpine actius" style={{
                        height: 830, margin: 'auto',
                        marginTop: 50, marginBottom: 50, fontSize: 15
                    }}>
                        <AgGridReact
                            rowData={this.state.actius}
                            columnDefs={this.state.columnes}
                            getRowStyles={getRowStyle}
                            pagination={true}
                            paginationPageSize={10}
                            rowHeight={70}>
                        </AgGridReact>
                    </div>
                </div>
            );
        }
    }
}