import React, { Component } from 'react';
import { BrowserRouter, NavLink, Route, Routes, useParams } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Nav, Navbar, Col, Container, Row, NavDropdown } from "react-bootstrap";
import '../styles/login.css'


export default class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
        }
    }

    onSubmit = (e) => {
        e.preventDefault();

        fetch('https://admin.europeanfundingproject.com/europeanfundingproject/public/api/login', {
            method: 'POST',
            body: "email=" + this.state.email + "&password=" + this.state.password,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        })
            .then(resposta => {
                return resposta.json()
            })
            .then(respostajson => {
                if (respostajson.status == "Login OK") {
                    alert("Sessió iniciada correctament");
                    sessionStorage.setItem("token", respostajson.result);
                    sessionStorage.setItem("id", respostajson.ID);
                    sessionStorage.setItem("email", respostajson.Email);
                    sessionStorage.setItem("admin", respostajson.Admin);
                    window.location.replace("/admin");
                } else {
                    alert("Login Incorrecte");
                }
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }


    render() {
        return (
            <Container className='containerLogin' style={{ fontSize: 25, marginTop: 100, maxWidth: 810 }}>

                <form onSubmit={this.onSubmit}>

                    <div className="row">
                        <div className="col-md-12">
                            <img className='logoLogin' src='https://europeanfundingproject.com/wp-content/uploads/2022/05/EUROPEAN-FUNDING-PROJECT-blanco.png'/>
                        </div>

                        <div className="col-md-12">
                            <div className="form-group">
                                <label style={{ padding: 20, fontWeight: 'bold' }}>Correu electrònic:</label>
                                <input type="text" className="form-control" name="email" onChange={this.onChange} />
                            </div>
                        
                            <div className="form-group">
                                <label style={{ padding: 20, fontWeight: 'bold' }}>Contrasenya:</label>
                                <input type="password" className="form-control" name="password" onChange={this.onChange} />
                            </div>
                        

                            <div className="form-group">
                                <input type="submit" className="btn boton btn-primary"
                                    value={"Iniciar Sessió"} style={{ fontSize: 25, width: 200, marginTop: 25, marginBottom: 25 }} />
                            </div>

                        </div>
                    </div>
                </form>

            </Container>

        );
    }
}