import React, { Component } from 'react';
import { BrowserRouter, NavLink, Route, Routes, useParams } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Nav, Navbar, Col, Container, Row, NavDropdown } from "react-bootstrap";
import Login from './sessions/Login';
import Logout from './sessions/Logout';
import Admin from './Admin';
import Llista_Actius from './llistats/Llista_Actius';
import ListadosBDD from './apartats_admin/ListadosBDD';
import Llista_Usuaris from './llistats/Llista_Usuaris';
import Llista_Tipus from './llistats/Llista_Tipus';
import Llista_Fotografies from './llistats/Llista_Fotografies';
import Edita_Usuari from './edicions/Edita_Usuari';
import Edita_Actius from './edicions/Edita_Actius';
import Edita_Tipus from './edicions/Edita_Tipus';
import Edita_Fotografies from './edicions/Edita_Fotografies';
import Vision_General from './apartats_admin/Vision_General';



export default class Menu extends Component {

    render() {
        return (
            <BrowserRouter>
                
                <Routes>
                    <Route path='/' element={< Login/>}/>
                    <Route path='/logout' element={< Logout/>}/>
                    <Route path='/admin' element={< Admin/>}/>
                    <Route path='/listadosbdd' element={< ListadosBDD/>}/>
                    <Route path='/visiongeneral' element={< Vision_General/>}/>
                    <Route path='/llista_actius' element={< Llista_Actius/>}/>
                    <Route path='/llista_usuaris' element={< Llista_Usuaris/>}/>
                    <Route path='/llista_tipus' element={< Llista_Tipus/>}/>
                    <Route path='/llista_fotografies' element={< Llista_Fotografies/>}/>
                    <Route path="/edita_actius/:id" element={<CridaEdita_Actius />} />
                    <Route path="/edita_tipus/:id" element={<CridaEdita_Tipus />} />
                    <Route path="/edita_usuari/:id" element={<CridaEdita_Usuari />} />
                    <Route path="/edita_fotografia/:id" element={<CridaEdita_Fotografia />} />
                </Routes>

            </BrowserRouter>

        );
    }
}

function CridaEdita_Usuari() {
    let params = useParams();
    return <Edita_Usuari id={params.id} />
}

function CridaEdita_Actius() {
    let params = useParams();
    return <Edita_Actius id={params.id} />
}

function CridaEdita_Tipus() {
    let params = useParams();
    return <Edita_Tipus id={params.id} />
}

function CridaEdita_Fotografia() {
    let params = useParams();
    return <Edita_Fotografies id={params.id} />
}
